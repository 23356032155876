import React, { useEffect, useState } from "react"
import type { PageProps } from "gatsby"
import { Link as BrowserLink } from "gatsby"
import { useSelector } from "react-redux"
import axios from "axios"

import {
  Box,
  Container,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  useMediaQuery,
  CircularProgress,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { LayoutChapters } from "../../components/Layout"
import { PNScrollAnimation } from "../../components/PNScrollAnimation"
import { Segment, Section } from "../../components/Layout"
import MailIcon from "@mui/icons-material/Mail"
import WebIcon from "@mui/icons-material/Web"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import InstagramIcon from "@mui/icons-material/Instagram"
import FacebookIcon from "@mui/icons-material/Facebook"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"

import { AppState } from "../../store/reducers/chapters.reducer"
import { SectionTitle } from "../../components/SectionTitle"
import { GET_CHAPTER_DETAILS } from "../../constants/api"

import { chapterImages } from "../../components/Chapters/Chapters.content"

interface Member {
  __typename: string
  website?: string
  zip: string
  companyName: string
  lastName: string
  industry: string
  createdAt: string
  email: string
  pnMembersPnChaptersId: string
  firstName: string
  state: string
  city: string
  updatedAt: string
  dob: string
  id: string
  phone: string
  address1: string
  facebook?: string
  businessInstagram?: string
  linkedIn?: string
}

interface ChapterDetailsResponse {
  members: Member[]
}

export const ChapterDetailSPage: React.FC<PageProps> = req => {
  const [members, setMembers] = useState<Member[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const chapterName = req.params.id || ""
  const chapterSlug = chapterName.replace(/-/g, "_")
  const chapters = useSelector((state: any) => state.chapters.chapters)
  const chapter = chapters.find(chapter => chapter.chapterSlug === chapterSlug)

  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"))

  useEffect(() => {
    if (chapterName) {
      axios
        .get<ChapterDetailsResponse>(
          `${GET_CHAPTER_DETAILS}?chapterName=${chapterName}`
        )
        .then(response => {
          setMembers(response.data.members)
          setLoading(false)
        })
        .catch(error => {
          console.error("Error fetching chapters data:", error)
          setLoading(false)
        })
    }
  }, [chapterName])

  if (!chapter) {
    return <div>Loading...</div>
  }

  return (
    <LayoutChapters>
      <Segment>
        <Container disableGutters>
          <Box mt="70px"></Box>
          <Grid container>
            <Grid item xs={12}>
              <Button
                component={BrowserLink}
                to="/#chapters"
                startIcon={<ArrowBackIcon />}
              >
                Previous Page
              </Button>
            </Grid>
          </Grid>
          <Section>
            <PNScrollAnimation animateIn="fadeIn">
              <Grid container>
                <Grid item xs={12} md={5}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      pr: isSmall ? 0 : 4,
                    }}
                    mb={isSmall ? 4 : 0}
                  >
                    <Box
                      component="img"
                      src={chapterImages[1]}
                      alt="male entrepreneur talking"
                      placeholder="blurred"
                      width={490}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={7}>
                  <Box>
                    <SectionTitle align={isSmall ? "center" : "initial"}>
                      {chapter.chapterName}
                    </SectionTitle>
                    <SectionTitle
                      align={isSmall ? "center" : "initial"}
                      subtitle
                      gutterBottom
                    >
                      {`${chapter.meetingDay} at ${chapter.meetingTime}`}
                    </SectionTitle>

                    <Box mb={2}>
                      <Typography
                        align={isSmall ? "center" : "initial"}
                        paragraph
                      >
                        {chapter.chapterIntro}
                      </Typography>
                    </Box>
                    <Button
                      href={`mailto:${chapter.chapterEmail}?subject=Request to Attend`}
                    >
                      Request To Attend
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </PNScrollAnimation>
          </Section>
        </Container>
        <Section>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Business Name</TableCell>
                  <TableCell align="right">Business Category</TableCell>
                  <TableCell align="right">City</TableCell>
                  <TableCell align="right">State</TableCell>
                  <TableCell align="right">Phone Number</TableCell>
                  <TableCell align="right">Email</TableCell>
                  <TableCell align="right">Website</TableCell>
                  <TableCell align="right">Social</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : (
                  members.map(member => (
                    <TableRow key={member.id}>
                      <TableCell>{`${member.firstName} ${member.lastName}`}</TableCell>
                      <TableCell align="right">{member.companyName}</TableCell>
                      <TableCell align="right">{member.industry}</TableCell>
                      <TableCell align="right">{member.city}</TableCell>
                      <TableCell align="right">{member.state}</TableCell>
                      <TableCell align="right">{member.phone}</TableCell>
                      <TableCell align="right">{member.email}</TableCell>
                      <TableCell align="right">
                        {member.website && (
                          <a
                            href={`https://${member.website}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <WebIcon />
                          </a>
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {member.facebook && (
                          <a
                            href={`https://${member.facebook}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FacebookIcon />
                          </a>
                        )}
                        {member.businessInstagram && (
                          <a
                            href={`https://${member.businessInstagram}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <InstagramIcon />
                          </a>
                        )}
                        {member.linkedIn && (
                          <a
                            href={`https://${member.linkedIn}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <LinkedInIcon />
                          </a>
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Section>
      </Segment>
    </LayoutChapters>
  )
}

export default ChapterDetailSPage
